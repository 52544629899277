<!-- 告警设置 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <div style="padding-top: 20px">
          <ykc-form ref="ruleForm" :model="model" :rules="rules">
            <ykc-form-item label="告警分类">
              {{ model.alarmType || '——' }}
            </ykc-form-item>
            <ykc-form-item label="告警事项">
              {{ model.alarmReason || '——' }}
            </ykc-form-item>
            <ykc-form-item label="告警等级" prop="alarmLevel">
              <ykc-dropdown
                placeholder="请选择告警等级"
                :data="alarmLevelList"
                v-model="model.alarmLevel"></ykc-dropdown>
            </ykc-form-item>
            <ykc-form-item label="是否通知管理员" prop="noticeFlag">
              <ykc-dropdown
                placeholder="请选择是否通知管理员"
                :data="noticeFlagList"
                v-model="model.noticeFlag"></ykc-dropdown>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { omManagementApi } from '@/service/apis';
  import { loadDicItem } from '@/utils/dictionary';

  const initModel = () => ({
    id: '',
    alarmType: '', // 告警分类
    alarmReason: '', // 告警事项
    alarmLevel: '', // 告警等级
    noticeFlag: '', // 是否通知管理员 0-否、1-是
  });

  export default {
    props: {
      currentData: {
        type: Object,
        default: () => {
          return {};
        },
      },
      showDrawer: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    data() {
      return {
        alarmLevelList: [],
        noticeFlagList: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        drawerTitle: '告警配置',
        model: initModel(),
        rules: {
          alarmLevel: [{ required: true, message: '请选择告警等级', trigger: 'blur' }],
          noticeFlag: [{ required: true, message: '请选择是否通知管理员', trigger: 'blur' }],
        },
      };
    },
    created() {
      this.alarmLevelList =
        loadDicItem('alarm_level').map(row => ({ id: Number(row.id), name: row.name })) || []; // 告警等级
    },
    methods: {
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        console.log(this.currentData, '+++++++++');
        if (this.currentData) {
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentData[key];
          });
        }
        console.log(this.model, '____________________');
      },
      // 保存
      beforeEnsure() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const reqParams = {
              ...this.model,
            };
            omManagementApi.alarmSetUpdate(reqParams).then(() => {
              this.model = initModel();
              this.$emit('closeDrawer');
              this.$emit('finish');
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
