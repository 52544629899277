<!-- 告警设置列表 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="告警设置列表"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <Edit
      :currentData="currentData"
      :showDrawer="showDialog"
      @finish="searchTableList"
      @closeDrawer="showDialog = false"></Edit>
  </scroll-layout>
</template>

<script>
  import Edit from './Edit.vue';
  import { omManagementApi } from '@/service/apis';
  import { code } from '@/utils';
  import { loadDicItem } from '@/utils/dictionary';

  export default {
    name: 'alarmSettingList',
    components: {
      Edit,
    },
    data() {
      return {
        currentData: {},
        showDialog: false,
        tableData: [],

        dictionaryAlarmType: [],
        dictionaryAlarmReason: [],
        dictionaryAlarmLevel: [],
        tableOperateButtons: [
          {
            enabled: () => code('maintain:alarm:setting:edit'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    console.log(row, 'row++');
                    this.currentData = {
                      id: row.id, // 主键Ids
                      alarmType: row.alarmType, // 告警分类
                      alarmReason: row.alarmReason, // 告警事项
                      alarmLevel: row.alarmLevel, // 告警等级
                      noticeFlag: row.noticeFlag, // 是否通知管理员 0-否、1-是
                    };
                    this.showDialog = true;
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          {
            label: '告警分类',
            prop: 'alarmType',
            width: 250,
          },
          {
            label: '告警事项',
            prop: 'alarmReason',
          },
          {
            label: '告警等级',
            prop: 'alarmLevelMsg',
            width: 300,
          },
          {
            label: '是否通知管理员',
            prop: 'noticeFlag',
            width: 300,
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { noticeFlag } = row;
                return <span>{(noticeFlag === 0 ? '否' : '是') || '——'}</span>;
              },
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          alarmType: '', // 告警分类
          alarmReason: '', // 告警事项
          alarmLevel: '', // 告警等级
          noticeFlag: '', // 是否通知管理员 0-否、1-是
        },
        alarmLevelData: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDropdown',
            key: 'alarmType',
            label: '告警分类',
            placeholder: '请选择告警分类',
            data: [...this.dictionaryAlarmType],
          },
          {
            comName: 'YkcDropdown',
            key: 'alarmReason',
            label: '告警事项',
            placeholder: '请选择告警事项',
            remote: true,
            data: [...this.dictionaryAlarmReason],
          },
          {
            comName: 'YkcDropdown',
            key: 'alarmLevel',
            label: '告警等级',
            placeholder: '请选择告警等级',
            data: [...this.dictionaryAlarmLevel],
          },
          {
            comName: 'YkcDropdown',
            key: 'noticeFlag',
            label: '是否通知',
            placeholder: '请选择是否通知',
            data: [...this.alarmLevelData],
          },
        ];
      },
    },
    created() {
      this.dictionaryAlarmType = loadDicItem('alarm_type') || []; // 告警分类
      this.dictionaryAlarmReason = this.mergeArrays(
        loadDicItem('alarm_reason_1'),
        loadDicItem('alarm_reason_2'),
        loadDicItem('alarm_reason_3'),
        loadDicItem('alarm_reason_4')
      ); // 告警事项
      this.dictionaryAlarmLevel = loadDicItem('alarm_level') || []; // 告警等级

      this.$nextTick(() => {
        this.searchTableList();
      });
    },
    methods: {
      // 重置编辑信息
      resetCurrentData(val) {
        if (!val) {
          this.currentData = null;
        }
      },
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        omManagementApi
          .alarmSetQueryPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /**
       * 合并多个数组
       */
      mergeArrays(...arrays) {
        // 使用reduce方法将所有数组合并为一个数组
        return arrays.reduce((mergedArray, currentArray) => {
          return mergedArray.concat(currentArray);
        }, []);
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appoint {
    width: 44px;
    height: 20px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    &-txt {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: 0 6px;
      font-size: 10px;
      transform: scale(0.83333);
      transform-origin: center center;
    }
    &-radius {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ffffff;
    }
  }
  .appoint-open {
    background: #1779fe;
    flex-direction: row;
  }
  .appoint-close {
    background: #4a4a4a;
    flex-direction: row-reverse;
  }
</style>
